import React from "react";

import Carousel from "react-multi-carousel";

import { Link } from "gatsby";

import "react-multi-carousel/lib/styles.css";

import banner1 from "assets/images/banner/forest-banner.png";

// import video from 'assets/images/banner/video.png';

export default function Banner() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  return (
    <div className="banner-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner-content-wrapper">
            <Carousel
              arrows={false}
              draggable={false}
              renderButtonGroupOutside={true}
              responsive={responsive}
            >
              <div className="img-wrapper">
                <div>
                  <img data-src={banner1} className="lazyload" alt="banner1" />
                  <div className="container banner-content">
                    <div className="row">
                      <div className="col-md-12 mb-5 text-center">
                        <Link
                          className="btn   atn-btn"
                          to="/donations-gifting"
                          target="_blank"
                        >
                          ADOPT A TREE NOW
                        </Link>
                      </div>
                    </div>
                    <div className="row banner-content-row">
                      <div className="col-sm-12 col-md-5 first-content">
                        <h3>We are planting millions of trees across India</h3>
                      </div>
                      <div className="col-md-2">
                        {/* <img src={video} alt='video' /> */}
                      </div>
                      <div className="col-sm-12 col-md-5 second-content">
                        <p>
                          An initiative of Heartfulness Institute to nurture,
                          protect and conserve native, endemic and endangered
                          tree species of India.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
