import React from 'react';

import SEO from 'components/seo/index';

import Layout from 'layout/index';

import Banner from 'components/home/Banner';
import Count from 'components/home/Count';
import Intro from 'components/home/Intro';
import DonationSection from 'components/home/DonationSection';
import DonationsGoSection from 'components/home/DonationsGoSection';
import SpentSection from 'components/home/SpentSection';
import Projects from 'components/home/Projects';
import PlantationDrive from 'components/home/PlantationDrive';
import RecentPlantations from 'components/home/RecentPlantations';
import PartnerCarousel from 'components/home/PartnerCarousel';
// import InstaFeed from 'components/home/InstaFeed';
import PressReleases from 'components/home/PressReleases';

import SimpleReactLightbox from 'simple-react-lightbox';
// import { ScrollingProvider } from 'react-scroll-section';

// markup
const IndexPage = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness' />
      <Layout isSticky={true} wrapperClass='home'>
        <section id='home'>
          <Banner />
        </section>
        <section id='count-section'>
          <Count />
        </section>
        <section id='introduction'>
          <Intro />
        </section>
        <section id='partner-carousel'>
          <PartnerCarousel />
        </section>
        <section id='donations-Gifting'>
          <DonationSection />
        </section>
        <section id='donation-go'>
          <DonationsGoSection />
        </section>
        <section id='spent'>
          <SpentSection />
        </section>
        <section id='projects'>
          <Projects />
        </section>
        <section id='process'>
          <PlantationDrive />
        </section>
        <SimpleReactLightbox>
          <section id='recent-plantation'>
            <RecentPlantations />
          </section>
        </SimpleReactLightbox>
        {/* <InstaFeed /> */}
        <section id='press-releases'>
          <PressReleases />
        </section>
      </Layout>
    </>
  );
};

export default IndexPage;
