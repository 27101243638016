import sapling from 'assets/images/spent/sapling.svg';
import plantation from 'assets/images/spent/plantation.svg';
import supportStaff from 'assets/images/spent/support-staff.svg';
import technologySoftware from 'assets/images/spent/technology-software.svg';
import plantainMaintance from 'assets/images/spent/plantain-maintance.svg';

export const spentSection = [
  {
    id: 1,
    img: sapling,
    alt: 'sapling',
    title: 'Average cost of <span>sapling</span>',
    price: '₹ 24',
  },
  {
    id: 2,
    img: plantation,
    alt: 'plantation',
    title: 'Cost of <span>plantation</span> (land prep, transport, labor, manure, equipment)',
    price: '₹ 36',
  },
  {
    id: 3,
    img: supportStaff,
    alt: 'supportStaff',
    title: '<span>Support staff</span> & admin cost',
    price: '₹ 25',
  },
  {
    id: 4,
    img: technologySoftware,
    alt: 'technologySoftware',
    title: 'Technology <span>& software</span>',
    price: '₹ 2',
  },
  {
    id: 5,
    img: plantainMaintance,
    alt: 'plantainMaintance',
    title: 'Average cost of <span>plantation & maintenance</span> for one-year',
    price: '₹ 93',
  },
];
