import React, { useState, useEffect } from 'react';

import Carousel from 'react-multi-carousel';

import { plantationDrive } from 'assets/data/plantation-drive';

import CarouselButtonGroup from 'utils/carousel-arrow';

import filDoc from 'assets/pdf/FBH_Identifying_Land_Document.pdf';

export default function PlantationDrive() {
  const [isMobile, setIsMobile] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 567,
        min: 320,
      },
      items: 1,
    },
  };

  useEffect(() => {
    window.innerWidth < 567 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <div className='plantation-drive-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section'>
              <div className='section-title'>
                <h3 className='text-start'>
                  Start a <span className='green'>plantation drive</span> near you
                </h3>
              </div>
              <div className='flow-box-wrapper'>
                <div className='row'>
                  {isMobile ? (
                    <Carousel
                      arrows={false}
                      autoPlaySpeed={3000}
                      draggable
                      infinite
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={true}
                      swipeable
                      ssr={false}
                      responsive={responsive}
                      customButtonGroup={<CarouselButtonGroup />}>
                      {plantationDrive.map((item) => {
                        return (
                          <div className='col-md-2' key={item.id}>
                            <div className='flow-box'>
                              <div className='top-section'>
                                <h4 className='count'>{item.count}</h4>
                                <p className='desc'>{item.desc}</p>
                              </div>
                              <div className='button-section'>
                                <h4 className='title'>{item.title}</h4>
                              </div>
                              <div className='bottom-section'>
                                <div className='img-wrapper '>
                                  <div className={`img ${item.img}`}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <>
                      {plantationDrive.map((item) => {
                        return (
                          <div className='col-md-2' key={item.id}>
                            <div className={`flow-box ${item.inverse ? 'odd' : ''}`}>
                              <div className='top-section'>
                                {item.inverse ? (
                                  <div className='img-wrapper '>
                                    <div className={`img ${item.img}`}></div>
                                  </div>
                                ) : (
                                  <>
                                    <h4 className='count'>{item.count}</h4>
                                    <p className='desc'>{item.desc}</p>
                                  </>
                                )}
                              </div>
                              <div className='button-section'>
                                <h4 className='title'>{item.title}</h4>
                              </div>
                              <div className='bottom-section'>
                                {item.inverse ? (
                                  <>
                                    <h4 className='count'>{item.count}</h4>
                                    <p className='desc'>{item.desc}</p>
                                  </>
                                ) : (
                                  <div className='img-wrapper '>
                                    <div className={`img ${item.img}`}></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='detail-button-wrapper'>
              <button className='btn btn-secondary'>
                <a href={filDoc} download>
                  DETAILED GUIDANCE DOCUMENT TO IDENTIFY LAND <i className='icon icon-download'></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
