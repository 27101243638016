import React, { useState } from "react";

import { Link, navigate } from "gatsby";
import AuthLogin from "components/Auth";

import DonateSaplings from "assets/images/donations-giting/ngo.png";
import GiftingSaplings from "assets/images/donations-giting/corporate.png";

export default function DonationSection() {
  const [userLog, setUserLog] = useState("");

  return (
    <div className="donation-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <section className="section">
              <div className="section-title">
                <h3>
                  <span className="white">Donations</span> & Gifting
                </h3>
              </div>
              <div className="para-section">
                <p>
                  All donations and gifts go towards “Forests by Heartfulness”
                  fund and will result in a matching number of saplings planted
                  in your name.
                </p>
                <div>
                  <Link
                    to="/donations-gifting"
                    target="_blank"
                    style={{ padding: "10px" }}
                  >
                    <img
                      src={DonateSaplings}
                      className="img-fluid donation-cards"
                      alt="DonateSaplings"
                    />
                  </Link>
                  <Link
                    to="/donations-gifting-corporate"
                    style={{ padding: "10px" }}
                  >
                    <img
                      src={GiftingSaplings}
                      className="img-fluid donation-cards-1"
                      alt="Gifting Saplings"
                    />
                  </Link>
                </div>
                <div>
                  <a style={{ padding: "10px" }}>
                    <AuthLogin
                      name="Track a Saplings"
                      onSignedIn={(userDetails) => {
                        setUserLog(userDetails);
                        navigate("/trackaSapling");
                      }}
                      signInBtnText="Track a Saplings Sign In"
                    />
                  </a>
                  <a style={{ padding: "10px" }}>
                    <AuthLogin
                      name="Plant a Saplings"
                      onSignedIn={(userDetails) => {
                        setUserLog(userDetails);
                        navigate("/planter-details");
                      }}
                      signInBtnText="Plant a Saplings Sign In"
                      signOutBtnText="Plant a Saplings Sign Out"
                    />
                  </a>
                </div>
                <p className="my-3 a-mail">
                  For corporate gifting and CSR contributions, kindly email us
                  at{" "}
                  <a href="mailto:fbh@heartfulness.org">fbh@heartfulness.org</a>
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
