export const plantationDrive = [
  {
    id: 1,
    count: '01.',
    desc: `Identify Land and make sure you have permission to plant trees there. Think about water source, fencing
    and protection for saplings. Download guidance for identifying the right land for plantation.`,
    title: 'IDENTIFY LAND',
    img: 'sprite-magnifier',
    inverse: false,
  },
  {
    id: 2,
    count: '02.',
    desc: `Identify the members who will join you for plantation drive.`,
    title: 'MAKE A TEAM',
    img: 'sprite-team',
    inverse: true,
  },
  {
    id: 3,
    count: '03.',
    desc: `Can be sourced from FBH nurseries (hyperlink with nursery sites) if you are within 100 km radius.
    Otherwise source them from a local nursery to save on transportation costs.`,
    title: 'GET THE SAPLINGS',
    img: 'sprite-sapling',
    inverse: false,
  },
  {
    id: 4,
    count: '04.',
    desc: `Prepare site for plantation. Source farmyard manure if possible. Keep saplings ready.`,
    title: 'PRE PLANTATION',
    img: 'sprite-planting-hand',
    inverse: true,
  },
  {
    id: 5,
    count: '05.',
    desc: `Arrive early at the site. If possible, conduct meditation for all volunteers before starting work. Click
    pictures of the team and email them to fbh@heartfulness.org.`,
    title: 'PLANTATION DAY',
    img: 'sprite-planting',
    inverse: false,
  },
  {
    id: 6,
    count: '06.',
    desc: `Water the saplings regularly for a year, especially in dry season.`,
    title: 'POST PLANTATION',
    img: 'sprite-water',
    inverse: true,
  },
];
