import React from 'react';

import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import intro1 from 'assets/images/intro/intro1.jpg';
import intro2 from 'assets/images/intro/intro2.jpg';
import intro3 from 'assets/images/intro/intro3.jpg';
import intro4 from 'assets/images/intro/intro4.jpg';
import intro5 from 'assets/images/intro/intro5.jpg';

// import hg from 'assets/images/intro/heartfulness-green.jpg';

export default function Intro() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className='carousel-button-group'>
        <div className='navigations'>
          <button onClick={() => previous()} className='me-3'>
            <i className='icon icon-tree-leaf-left'></i>
          </button>
          <button onClick={() => next()}>
            <i className='icon icon-tree-leaf-right'></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="intro-section">
      <div className="section pt-0">
        <div className="container">
          <div className="row intro-section-row">
            <div className="col-md-6">
              <div className="section-title">
                <h3 className="text-start">
                  <span className="green">Forests</span> <br /> by Heartfulness
                </h3>
                <h4>Introduction</h4>
              </div>
              <div className="para-section">
                <p>
                  Our primary goal is to plant at-least 30 million native and
                  endemic trees across India by 2025.
                </p>
                <p>
                  In order to achieve this goal, a team of forestry,
                  agro-forestry and climate experts are guiding the project and
                  will continue to do so for the next 5 years. Under their
                  guidance, as a first step, nurseries are being developed in 18
                  cities across the country.
                </p>
                <p>
                  Saplings of carefully selected tree and plant species are
                  raised in these nurseries during non-plantation season every
                  year between October & May.
                </p>
                <p>
                  Mass plantation of these saplings takes place every year
                  between June – September (monsoon) and Jan – Feb (late
                  winter). This will be carried out by the Heartfulness
                  volunteers, farmers, NGOs, school children and general public.
                  Planted saplings will be effectively monitored and nurtured by
                  providing optimum conditions for growth to ensure high
                  survival rate.
                </p>
                <p>
                  FBH has already conducted a successful mass plantation drive
                  in the year 2019 covering 64 cities – 64,000 trees – in one
                  day through its volunteers and its partnering organizations
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="intro-carousel">
                <Carousel
                  arrows={false}
                  autoPlaySpeed={3000}
                  draggable
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  partialVisible
                  renderButtonGroupOutside={true}
                  swipeable
                  responsive={responsive}
                  customButtonGroup={<ButtonGroup />}
                >
                  <div className="img-wrapper">
                    <img
                      data-src={intro1}
                      className="lazyload"
                      alt="Daaji-planting-a-sapling"
                    />
                  </div>
                  <div className="img-wrapper">
                    <img
                      data-src={intro2}
                      className="lazyload"
                      alt="forest-by-heartfulness"
                    />
                  </div>
                  <div className="img-wrapper">
                    <img
                      data-src={intro3}
                      className="lazyload"
                      alt="Children-with-placards"
                    />
                  </div>
                  <div className="img-wrapper">
                    <img
                      data-src={intro4}
                      className="lazyload"
                      alt="Little-boy-praying-with-sapling"
                    />
                  </div>
                  <div className="img-wrapper">
                    <img
                      data-src={intro5}
                      className="lazyload"
                      alt="Planters-meditating"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
            {/* <div className='col-md-12'>
              <div className='d-flex justify-content-center align-items-center pt-3'>
                <img src={hg} alt='heartfulness green' />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ padding: "0pc 55px", cursor: "pointer" }}
      >
        <div className="row intro-section-row">
          <h5>
            <a
              href="https://cdn-prod.heartfulness.org/FBH/fbh_annual_report_1.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>Read our 2022 Annual Report</b>
            </a>
          </h5>
        </div>
      </div>
    </div>
  );
}
